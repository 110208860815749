import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import { DidomiModal } from '../components';

export const ModalPortal: React.FC<any> = (props: any) => {
    const [container] = useState(() => {
        let tmpContainer = document.querySelector(props.attachTo) || document.querySelector('.didomi-overlay-container');
        if (!tmpContainer) {
            tmpContainer = document.createElement('div');
            tmpContainer.classList.add('didomi-overlay-container');
            document.body.appendChild(tmpContainer);
        }
        return tmpContainer;
    });
  
    return createPortal(props.children, container)
  }
  
export const DidomiModalPortalized: React.FC<any> = (props: any) => {
    return <ModalPortal><DidomiModal {...props}>{props.children}</DidomiModal></ModalPortal>
}
  