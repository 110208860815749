// @ts-nocheck
import React from 'react';
import { DidomiTextInput } from '../../components';
import { Field, FieldProps } from 'formik';
import { getError, handleFocusout, handleValueChange } from '../utils/utils';
import type { JSX } from '@didomi/ui-atoms';

const DidomiTextInputField = (props: JSX.DidomiTextInput & { className?: string; }) => {
  return (
    <Field name={props.name} type="text">
      {({field, form}: FieldProps) =>
        <DidomiTextInput
          className={props.className}
          value={field.value}
          error={getError(field.name, form)}
          onFocusout={() => handleFocusout(field, form)}
          onValueChange={(event: CustomEvent) => handleValueChange(field, form, event)}
          {...props}
          />
      }
    </Field>
  );
};

export default DidomiTextInputField;

