import { FormikProps, FieldInputProps } from 'formik';

/**
 * If the form field is invalid and touched, returns the error, otherwise returns `undefined`.
 *
 * @param name the name of the field
 * @param name the Formik form state
 */
export function getError(name: string, form: FormikProps<any>): string | undefined {
    if (form.errors[name] && form.touched[name]) {
        return form.errors[name] as string;
    }

    return undefined;
}

/** 
 * Handles the `focusout` event for the Field component.
   * We use `focusout` over `blur` because `focusout` supports bubbling.
   * This means that the `blur` event won't be triggered because we aren't listening directly to the native input, 
   * but `focusout` on the other hand will bubble from the native input to the parents.
 */
export function handleFocusout(field: FieldInputProps<any>, form: FormikProps<any>): void  {
    form.setFieldValue(field.name, field.value, true);
    form.setFieldTouched(field.name, true, false);
};

/** Handle the `valueChange` event for the Field component */
export function handleValueChange(field: FieldInputProps<any>, form: FormikProps<any>, event: CustomEvent<string>): void {
    form.setFieldValue(field.name, event.detail, form.touched.hasOwnProperty(field.name));
};
